@keyframes fadeInUp {
  0% {
    transform: translate3d(0, 25px, 0);
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: translateZ(0);
  }
}

/* Hides a leaf towards the very end of the animation */
@keyframes fade {
  /* Show a leaf while into or below 95 percent of the animation and hide it, otherwise */
  0% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  /* Show a leaf while into or below 95 percent of the animation and hide it, otherwise */
  0% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Makes a leaf fall from -300 to 600 pixels in the y-axis */
@keyframes drop {
  /* Move a leaf to -300 pixels in the y-axis at the start of the animation */
  0% {
    -webkit-transform: translate(0px, -50px);
  }
  /* Move a leaf to 600 pixels in the y-axis at the end of the animation */
  100% {
    -webkit-transform: translate(0px, 650px);
  }
}
@-webkit-keyframes drop {
  /* Move a leaf to -300 pixels in the y-axis at the start of the animation */
  0% {
    -webkit-transform: translate(0px, -50px);
  }
  /* Move a leaf to 600 pixels in the y-axis at the end of the animation */
  100% {
    -webkit-transform: translate(0px, 650px);
  }
}

/* Rotates a leaf from -50 to 50 degrees in 2D space */
@keyframes clockwiseSpin {
  /* Rotate a leaf by -50 degrees in 2D space at the start of the animation */
  0% {
    -webkit-transform: rotate(-60deg);
  }
  /*  Rotate a leaf by 50 degrees in 2D space at the end of the animation */
  100% {
    -webkit-transform: rotate(60deg);
  }
}
@-webkit-keyframes clockwiseSpin {
  /* Rotate a leaf by -50 degrees in 2D space at the start of the animation */
  0% {
    -webkit-transform: rotate(-60deg);
  }
  /*  Rotate a leaf by 50 degrees in 2D space at the end of the animation */
  100% {
    -webkit-transform: rotate(60deg);
  }
}

/* Flips a leaf and rotates it from 50 to -50 degrees in 2D space */
@keyframes counterclockwiseSpinAndFlip {
  /* Flip a leaf and rotate it by 50 degrees in 2D space at the start of the animation */
  0% {
    -webkit-transform: scale(-1, 1) rotate(50deg);
  }
  /* Flip a leaf and rotate it by -50 degrees in 2D space at the end of the animation */
  100% {
    -webkit-transform: scale(-1, 1) rotate(-60deg);
  }
}
@-webkit-keyframes counterclockwiseSpinAndFlip {
  /* Flip a leaf and rotate it by 50 degrees in 2D space at the start of the animation */
  0% {
    -webkit-transform: scale(-1, 1) rotate(50deg);
  }
  /* Flip a leaf and rotate it by -50 degrees in 2D space at the end of the animation */
  100% {
    -webkit-transform: scale(-1, 1) rotate(-60deg);
  }
}

.fadeInUp {
  visibility: hidden;
  opacity: 0;
  animation: fadeInUp 0.8s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  -webkit-animation: fadeInUp 0.8s;
  -webkit-animation-delay: 0.3s;
  -webkit-animation-fill-mode: forwards;
}

/*
* Data-aos
*/
/* [data-aos^=zoom][data-aos^=zoom] {
  visibility: hidden;
}
[data-aos^=zoom][data-aos^=zoom].aos-animate {
   visibility:visible;
} */

@keyframes BoxOver {
  0% {
    transform: scale(1.05);
  }

  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

@keyframes BoxOverOut {
  0% {
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    opacity: 1;
    border-width: 2px;
  }
  80% {
    width: calc(100% + 24px);
    height: calc(100% + 24px);
    opacity: 0;
    border-width: 8px;
  }
  100% {
    width: calc(100% + 24px);
    height: calc(100% + 24px);
    opacity: 0;
    border-width: 8px;
  }
}
